"use client";
import { usePathname } from "next/navigation";
import { useState } from "react";
import { ChevronDown, LogOut } from "lucide-react";
import Image from "next/image";
import NeticLogo from "@/public/wordmarkai-black.svg";
import DashboardTopbarLink from "@/app/dashboard/top-bar/dashboard-topbar-link";
import env from "@/lib/env-public";

export default function TopBar() {
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState(false);

  // Handlers for mouse enter and leave events - Campaigns dropdown
  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  return (
    <div className="text-black px-4 py-2 flex flex-row items-center shadow-lg z-10 relative justify-between">
      <div className="flex flex-row items-center relative">
        <div className="min-w-32">
          <Image alt="Logo" src={NeticLogo} width={100} height={18} />
        </div>
        <div className="flex flex-row items-center relative space-x-7">
          <DashboardTopbarLink href="/dashboard/conversations/all/open">Conversations</DashboardTopbarLink>

          <div className="relative inline-block" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <DashboardTopbarLink href="/dashboard/campaigns">
              <div className="flex flex-row items-center gap-x-1">
                Campaigns
                <ChevronDown size={18} />
              </div>
            </DashboardTopbarLink>
            {isOpen && (
              <div
                onMouseEnter={handleMouseEnter}
                className="block absolute bg-white min-w-48 drop-shadow-xl pt-1 z-1 rounded-lg"
              >
                <div className="my-2 mx-2">
                  <a
                    className={`block rounded-lg px-2 py-2 hover:bg-gray-200 text-sm ${pathname?.startsWith("/dashboard/campaigns/audiences") ? "bg-gray-100 text-blue-700" : ""}`}
                    href="/dashboard/campaigns/audiences"
                  >
                    Audiences
                  </a>
                </div>
              </div>
            )}
          </div>
          <DashboardTopbarLink href="/dashboard/calls">Calls</DashboardTopbarLink>
        </div>
      </div>
      <DashboardTopbarLink href="/sign-out" className="hover:text-red-500">
        <div className="flex flex-row items-center gap-x-1">
          <LogOut size={16} />
          Sign Out
        </div>
      </DashboardTopbarLink>
    </div>
  );
}
